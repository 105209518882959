import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Math from "../components/Math"
import ContactSection from "../components/Home/ContactSection"
import Calculator from "../components/Calculator"
import History from "../components/Math/History"
import HowToCalc from "../components/Home/CalculatorSection/HowToCalc"

const CalcPage = () => (
  <Layout>
    {
      // eslint-disable-next-line
      <SEO
        title="Калькулятор стоимости"
        description="Расчет стоимости катализатора по содержанию платины, палладия и родия"
      />
    }
    <section
      id="calc"
      aria-labelledby="calc-heading"
      className="mx-auto max-w-xl py-6 px-0 sm:py-32 md:px-12 lg:max-w-7xl"
    >
      <h1
        id="calc-heading"
        className="pt-20 text-center text-4xl font-extrabold md:pt-0 md:text-5xl"
      >
        Калькулятор стоимости
      </h1>
      <h2 className="pt-0 text-center text-xl font-light sm:block sm:pt-2 md:text-2xl">
        Расчет стоимости катализатора по содержанию платины, палладия и родия
      </h2>

      <div className="grid max-w-7xl grid-cols-1 justify-items-center space-y-2 pt-10 lg:grid-cols-3 lg:pt-8">
        <Calculator />
        <Math />
        <History />
      </div>
    </section>
    <HowToCalc />
    <ContactSection />
  </Layout>
)

export default CalcPage
