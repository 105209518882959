import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"

import { DocumentDuplicateIcon } from "@heroicons/react/outline"

import { mathCalculationAtom } from "../atoms"
import { isNumber } from "../helpers"

import InputLME from "./InputLME"
import usePublicRates from "../../hooks/usePublicRates"

export default function Calculator() {
  const { USDRUB, ptValue, plValue, rhValue } = usePublicRates()

  const [usdRub, setUsdRub] = useState(USDRUB)
  const [weight, setWeight] = useState(1)

  const [pt, setPt] = useState(null)
  const [pd, setPd] = useState(null)
  const [rh, setRh] = useState(null)

  const gramsToOz = 0.0321507

  const [lmePt, setLmePt] = useState(75)
  const [lmePd, setLmePd] = useState(75)
  const [lmeRh, setLmeRh] = useState(75)

  const [sum, setSum] = useState(0)

  useEffect(() => {
    let sum =
      (pt * ptValue * lmePt + pd * plValue * lmePd + rh * rhValue * lmeRh) *
      0.01 *
      gramsToOz *
      usdRub *
      weight
    setSum(sum.toFixed(0))
  }, [pt, pd, rh, lmePt, lmePd, lmeRh, weight, usdRub])

  const [calculation, setCalculation] = useRecoilState(mathCalculationAtom)

  const pushCalculation = () => {
    if (isNumber(parseInt(calculation[calculation.length - 1]))) {
      setCalculation([...calculation, "+", sum])
    } else {
      setCalculation([...calculation, sum])
    }
  }

  return (
    <>
      <div className="flex w-full items-center justify-center px-6 drop-shadow-xl sm:px-0">
        <div className="grid h-[30rem] w-[20rem] grid-cols-1 rounded-xl bg-white">
          <div className="grid grid-cols-1 space-y-2 p-3">
            <div className="flex items-center justify-between">
              <label
                htmlFor="weight"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                ВЕС
              </label>
              <div className="relative mt-1">
                <input
                  id="weight"
                  value={weight}
                  type="number"
                  step="0.01"
                  className="form-input block w-full border border-gray-300 py-2 pl-7 pr-2 text-sm text-gray-900 shadow-sm sm:py-1 sm:leading-5"
                  aria-describedby="weight"
                  onChange={e => {
                    setWeight(e.target.value)
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                  <span className="text-sm text-gray-500 sm:leading-5">кг</span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="usd-rub"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                КУРС $
              </label>
              <div className="relative mt-1">
                <input
                  id="usd-rub"
                  value={usdRub}
                  type="number"
                  step="0.01"
                  className="form-input block w-full border border-gray-300 py-2 pl-7 pr-2 text-sm text-gray-900 shadow-sm sm:py-1 sm:leading-5"
                  aria-describedby="usd-rub"
                  onChange={e => {
                    setUsdRub(e.target.value)
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                  <span className="text-sm text-gray-500 sm:leading-5">
                    руб/$
                  </span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <label
                htmlFor="pt"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                PT
              </label>
              <div className="relative mt-1">
                <input
                  id="pt"
                  type="number"
                  step="0.1"
                  min="0"
                  className="form-input block w-full border border-gray-300 py-2 pl-7 pr-2 text-sm text-gray-900 shadow-sm sm:py-1 sm:leading-5"
                  placeholder="0.00"
                  value={pt}
                  aria-describedby="pt"
                  onChange={e => {
                    setPt(e.target.value)
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                  <span className="text-sm text-gray-500 sm:leading-5">
                    грамм/кг
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="pd"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                PD
              </label>
              <div className="relative mt-1">
                <input
                  id="pd"
                  type="number"
                  step="0.1"
                  min="0"
                  className="form-input block w-full border border-gray-300 py-2 pl-7 pr-2 text-sm text-gray-900 shadow-sm sm:py-1 sm:leading-5"
                  placeholder="0.00"
                  value={pd}
                  aria-describedby="pd"
                  onChange={e => {
                    setPd(e.target.value)
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                  <span className="text-sm text-gray-500 sm:leading-5">
                    грамм/кг
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="rh"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                RH
              </label>
              <div className="relative mt-1">
                <input
                  id="rh"
                  type="number"
                  step="0.1"
                  min="0"
                  className="form-input block w-full border border-gray-300 py-2 pl-7 pr-2 text-sm text-gray-900 shadow-sm sm:py-1 sm:leading-5"
                  placeholder="0.00"
                  value={rh}
                  aria-describedby="rh"
                  onChange={e => {
                    setRh(e.target.value)
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-8">
                  <span className="text-sm text-gray-500 sm:leading-5">
                    грамм/кг
                  </span>
                </div>
              </div>
            </div>

            <InputLME
              label="Pt"
              value={lmePt}
              setValue={setLmePt}
              rate={ptValue}
              usdRub={usdRub}
            />
            <InputLME
              label="Pd"
              value={lmePd}
              setValue={setLmePd}
              rate={plValue}
              usdRub={usdRub}
            />
            <InputLME
              label="Rh"
              value={lmeRh}
              setValue={setLmeRh}
              rate={rhValue}
              usdRub={usdRub}
            />
          </div>

          <div className="flex items-center justify-between rounded-b-xl bg-gray-700 py-1 px-2 text-5xl font-light text-white">
            <div>{sum} ₽</div>
            <button
              onClick={pushCalculation}
              id="copy-math-result"
              aria-label="Copy math result"
            >
              <DocumentDuplicateIcon className="h-10 w-10" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
