import React from "react"

const InputLME = ({ label, value, setValue, rate, usdRub }) => {
  return (
    <div>
      <label htmlFor={`${label}-lme`} className="sr-only">
        {label}
      </label>
      <div className="flex justify-between divide-x divide-gray-300 rounded-md border border-gray-300 bg-gray-50 shadow-sm">
        <div className="relative flex">
          <span className="inline-flex items-center border-r border-gray-300 px-2 text-sm text-gray-500 sm:text-xs">
            {label}
          </span>
          <input
            value={value}
            onChange={e => setValue(e.target.value)}
            type="number"
            name={`${label}-lme`}
            id={`${label}-lme`}
            className="block w-14 flex-1 px-2 py-2 text-sm text-gray-500 focus:border-orange-500 focus:ring-orange-500 sm:text-xs"
            placeholder="60"
            min="1"
            max="99"
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span
              className="text-sm text-gray-500 sm:text-xs"
              id={`${label}-lme-percentage`}
            >
              %
            </span>
          </div>
        </div>
        <div className="flex flex-1 items-center justify-center px-2 text-sm text-gray-800 sm:text-xs">
          {((rate / 31.1) * (value / 100) * usdRub).toFixed(0)} ₽/гр
        </div>
        <div className="flex flex-1 items-center justify-center px-2 text-sm text-gray-800 sm:text-xs">
          {rate} $/oz
        </div>
      </div>
    </div>
  )
}

export default InputLME
