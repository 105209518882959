import React from "react"
import { useRecoilState } from "recoil"
import { calcHistoryAtom } from "../atoms"

export default function History() {
  const [history, setHistory] = useRecoilState(calcHistoryAtom)
  return (
    <div className="flex w-full items-center justify-center drop-shadow-xl">
      <div className="grid h-[30rem] w-[20rem] grid-cols-1 content-start justify-items-start rounded-xl bg-white p-3">
        <div className="border-b border-gray-500 pb-2">
          <div className="flex justify-between">
            <div className="text-xl text-corp-orange">История расчетов</div>
            <button
              onClick={() => setHistory([])}
              className="rounded-full bg-corp-orange py-0.5 px-2 text-sm text-white hover:bg-orange-500"
            >
              Очистить
            </button>
          </div>
          <div className="pt-1 text-sm text-gray-700">
            Запись добавляется после нажатия на знак "=" (равно)
          </div>
        </div>
        <div className="overflow-y-hidden pt-2 text-sm text-gray-700">
          {history.length > 0
            ? [...history]
                .reverse()
                .map((record, index) =>
                  index < 15 ? (
                    <div key={`math-history-${index}`}>{record}</div>
                  ) : null
                )
            : null}
        </div>
      </div>
    </div>
  )
}
