import React from "react"
import {
  LightningBoltIcon,
  BadgeCheckIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline"

import { StaticImage } from "gatsby-plugin-image"

const transferFeatures = [
  {
    id: 1,
    name: "Точность измерения",
    description: "Прибор сертифицирован и проходит поверку каждые полгода",
    icon: BadgeCheckIcon,
  },
  {
    id: 2,
    name: "Выезд специалиста",
    description:
      "Специалист с прибором приедет в удобное для вас время и место",
    icon: LocationMarkerIcon,
  },
  {
    id: 3,
    name: "Скорость измерения",
    description:
      "15 минут занимает анализ и расчет выплаты за ваш катализатор или сажевый фильтр",
    icon: LightningBoltIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: "Ввод показаний",
    description:
      "Введите вес, содержание платины, палладия и родия в грамм на кг",
    icon: CalculatorIcon,
  },
  {
    id: 2,
    name: "Итоговая сумма",
    description:
      "После ввода показаний отобразится сумма, за которую мы готовы СЕГОДНЯ купить у вас катализатор",
    icon: CurrencyDollarIcon,
  },
]

export default function HowToCalc() {
  return (
    <div className="overflow-hidden bg-gray-50 py-8 lg:py-12" id="how_to_calc">
      <div className="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
          />
        </svg>

        <div id="how-to-calc" className="relative">
          <h2 className="text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            Как работать с калькулятором
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-600">
            Несколько простых шагов для вычисления точной цены катализатора по
            показаниям анализатора драгоценных металлов
          </p>
        </div>

        <div className="relative mt-6 lg:mt-12 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Анализ катализатора
            </h3>
            <p className="mt-3 text-lg text-gray-600">
              Вычислить точную стоимость катализатора или сажевого фильтра можно
              только с помощью специального прибора - XFR анализатора. Для
              измерения вашего катализатора вы можете воспользоваться БЕСПЛАТНЫМ
              вызовом нашего специалиста.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map(item => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-corp-orange text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-600">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={784}
                height={404}
                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
              />
            </svg>
            <div className="relative flex justify-center">
              <StaticImage
                width={490}
                src="../../../assets/images/calc/hand.png"
                placeholder="blurred"
                layout="fixed"
                alt="Анализатор драгоценных металлов"
              />
            </div>
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
          />
        </svg>

        <div className="relative mt-6 sm:mt-8 lg:mt-12">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                Самостоятельный расчет стоимости
              </h3>
              <p className="mt-3 text-lg text-gray-600">
                Узнав содержание драгоценных металлов, мы можете самостоятельно
                расчитать стоимость с помощью нашего калькулятора
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map(item => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-corp-orange text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-600">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={784}
                  height={404}
                  fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                />
              </svg>
              <div className="relative flex justify-center">
                <StaticImage
                  width={408}
                  src="../../../assets/images/calc/screen.png"
                  placeholder="blurred"
                  layout="fixed"
                  alt="Калькулятор стоимости катализатора автомобиля"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
